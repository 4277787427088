import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createBlock(_component_van_tabbar, {
    route: "",
    modelValue: _ctx.active,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.active = $event),
    onChange: _ctx.onChange,
    placeholder: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/",
      icon: "add-o",
      name: "index"
    }, {
      default: _withCtx(() => [_createTextVNode("添加")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      replace: "",
      to: "/list",
      icon: "todo-list-o",
      name: "list"
    }, {
      default: _withCtx(() => [_createTextVNode("历史")]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onChange"]);
}